import {useTheme} from "next-themes";
import {SVGProps, useEffect, useState} from "react";


export const HeaderLogo = (props: SVGProps<any>) => {
  const {theme} = useTheme()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  let outerPlate = '#3a474e'
  let innerPlate = '#616c71'

  if (isMounted) {
    outerPlate = theme === 'dark'
      ? '#3a474e'
      : '#616c71'

    innerPlate = theme === 'dark'
      ? '#616c71'
      : '#3a474e'
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width='50px'
      height='50px'
      {...props}
      viewBox='0 0 512 512'
    >
      <defs>
        <style>
          {`
          .cls-3{fill:#e78438}
          .cls-4{fill:#eeab46}
          .cls-5{fill:#e3e8f6}
          `}
        </style>
      </defs>
      <g id="Layer_28">
        <circle
          cx={256}
          cy={256}
          r={240}
          style={{
            fill: outerPlate,
          }}
        />
        <circle
          cx={256}
          cy={256}
          r={208}
          style={{
            fill: innerPlate,
          }}
        />
        <path
          d="M160.655 208.487 96.444 475.981a15.959 15.959 0 1 0 31.031 7.468l64.412-268.425Z"
          className="cls-3"
        />
        <path
          d="m143.61 141.791-3.751 15.673a47.875 47.875 0 0 0 93.092 22.4L236.7 164.2Z"
          className="cls-4"
        />
        <path
          d="M248.566 78.508a8.023 8.023 0 0 0-9.644 5.922l-18.173 75.925 15.557 3.744 18.178-75.951a8 8 0 0 0-5.918-9.64ZM211.915 158.229l18.9-75.612a8 8 0 0 0-15.523-3.875l-18.934 75.744ZM185.216 151.8c.009-.031.03-.054.038-.085l19.019-76.086a8 8 0 0 0-15.523-3.875l-19.02 76.086c-.019.078.007.153-.01.231ZM159.57 145.632l17.844-74.57a8 8 0 1 0-15.562-3.718l-17.839 74.544Z"
          className="cls-5"
        />
        <path
          d="M175.524 211.6 108 495.191c.081.021.152.062.234.082a15.954 15.954 0 0 0 19.239-11.824l64.412-268.426Z"
          className="cls-4"
        />
        <path
          d="M409.573 476.664 370.5 297.581l-31.179 6.788 39.076 179.084a15.955 15.955 0 0 0 31.18-6.789Z"
          className="cls-3"
        />
        <path
          d="M323.019 80a115.26 115.26 0 0 0-40.367 114.364l25.485 116.794 62.363-13.577Z"
          className="cls-5"
        />
        <path
          d="m370.5 297.581-45.163-206.96a115.2 115.2 0 0 0-19.237 92.062l28.59 122.694Z"
          style={{
            fill: "#f8f7fe",
          }}
        />
        <path
          d="m370.5 297.581-16.188 3.519 42.726 194.57c.115-.022.228-.014.343-.039a15.938 15.938 0 0 0 12.192-18.967Z"
          className="cls-4"
        />
      </g>
    </svg>
  )
}
