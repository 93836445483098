export const PostSignature = () => (
  <>
    <div className='divide-black pt-6 pb-6 text-gray-700 dark:text-gray-300'>
      <p className='mb-5'>
        Stay hungry, my friends, and remember: {"life's"} too short for bad food,
      </p>

      <p>Shane Daugherty</p>
    </div>
  </>
)
