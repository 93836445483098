import {SiteMetadata} from "@blogs/types";
import {HeaderLogo} from "../components/HeaderLogo";
import {PostSignature} from "../components/PostSignature";

export const siteMetadata: SiteMetadata = {
  title: 'Forking Feed Me',
  author: 'Shane Daugherty',
  headerTitle: 'Feed Me',
  description: 'Restaurant reviews around the world from a hungry person.',
  language: 'en-us',
  theme: 'system', // system, dark or light
  siteUrl: 'https://www.forkingfeedme.com',
  siteLogo: '/static/images/logo.png',
  image: '/static/images/avatar.jpeg',
  socialBanner: '/static/images/twitter-card.png',
  email: 'shane@shanedaugherty.com',
  locale: 'en-US',
  types: [{ name: 'reviews' }],
  postSignature: PostSignature,
  IMAGE_PROXY_TEMPLATE: `https://images.forkingfeedme.com/extra-secure/rs:fill:$\{width}:$\{height}:0:0/g:ce/sm:1/dpr:$\{dpi}/plain/$\{source}`,
  header: {
    logo: HeaderLogo
  },
  footer: {
    tagline: 'Restaurant Reviews'
  },
  analytics: {
    googleAnalyticsId: 'G-PJESDVMK2X',
  },
  newsletter: {
    provider: 'mailchimp',
  },
  comment: {
    provider: 'disqus',
    disqusConfig: {
      shortname: 'forkingfeedme'
    }
  },
}
